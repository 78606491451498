import React from 'react';
import { NextPage } from 'next';

import { GiftsLandingPage } from '@/src/components/pages/gifts-landing';
import { HeadingTemplate } from '@/src/components/layouts/heading-template/heading-template.component';

const Home: NextPage = (props) => {
  return (
    <>
      <HeadingTemplate />

      <GiftsLandingPage {...props} />
    </>
  );
};

export default Home;
